import { EditOutlined } from "@mui/icons-material";
import { Box, Button, Card, CardContent, CardHeader, FormControlLabel, FormGroup, IconButton, Input, InputAdornment, Switch, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from '@mui/icons-material/Send';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { tostE, tostS } from "src/utils/Toast";
import { deleteTemplate, getAllTemplate, changeStatus } from "src/api/emailTemplateServices";
import ViewEmailTemplate from "./ViewEmailTemplate";
import { capitalize } from "src/utils/helpers";
import SendEmail from "./SendEmail";
import { STATUS_OPTIONS } from "src/utils/constants";
import TableFilter from "src/components/shared/TableFilter";

function EmailTemplateManagement() {
    const [data, setData] = useState();
    const navigate = useNavigate();
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [modalShow, setModalShow] = useState(false);
    const [mailShow, setMailShow] = useState(false);
    const [statusId, setStatusId] = useState("");
    const [viewId, setViewId] = useState("");
    const [editId, setEditId] = useState("");
    const [searchItem, setSearchItem] = useState("");
    const [filteredData, setFilteredData] = useState("");
    const [warning, setWarning] = useState("");
    const [action, setAction] = useState("");
    const [refresh, setRefresh] = useState(false)
    const [show, setShow] = useState(false);
    const [currentStatus, setCurrentStatus] = useState("");
    const [key, setKey] = useState("createdAt");
    const [sort, setSort] = useState(-1);
    const [status, setStatus] = useState([]);

    const handleSort = (column, sortDirection) => {
        setKey(column.sortField);
        setSort(sortDirection === "asc" ? 1 : -1);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        const body = {
            searchItem: searchItem ? searchItem : "",
            pageNumber: currentPage,
            pageSize: pageSize,
            status: status,
            sortBy: key,
            sortOrder: sort
        };
        getAllTemplate(body)
            .then((res) => {
                setData(res.data.data.result);
                setTotalPages(res.data.data.totalRecords);
            })
            .catch((err) => {
                setData("");
                setTotalPages("");
            });
    }, [searchItem, currentPage, pageSize, refresh, sort, key, status]);


    const handleClose = () => setShow(false);

    const columns = [
        {
            name: "Sr. No.",
            selector: (row, index) => {
                return (((currentPage-1)*pageSize)+index+1)
            },
        },
        {
            name: "Title",
            sortField: "title",
            sortable: true,
            selector: (row) => (row?.title ? capitalize(row?.title) : "-"),
        },
        {
            name: "Subject",
            sortField: "subject",
            sortable: true,
            selector: (row) => (row?.subject ? capitalize(row?.subject) : "-"),
        },
        {
            name: "Created By",
            selector: (row) => (row.createdBy ? capitalize(row?.createdBy?.name) : "-"),
        },
        {
            name: "Created At",
            sortField: "createdAt",
            sortable: true,
            selector: (row) => (row.createdAt ? <Moment format="DD/MM/YYYY  HH:MM A">{row.createdAt}</Moment> : "-"),
        },
        {
            name: "Status",
            cell: (row) => (
                <>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={row.status}
                                    onClick={() => {
                                        setShow(true);
                                        setCurrentStatus(row.status);
                                        setStatusId(row._id);
                                        setAction("status");
                                        setWarning("Are you sure want to change the status? ");
                                    }}
                                    inputProps={{ "aria-label": "controlled" }}
                                />
                            }
                        />
                    </FormGroup>
                </>
            ),
        },
        {
            name: <div style={{ textAlign: "center", marginLeft: "40px" }}>Action</div>,
            cell: (row) => (
                <>
                    <Tooltip title="Edit" placement="left">
                        <IconButton
                            color="primary"
                            onClick={() => {
                                setEditId(row._id);
                                navigate(`/email-template/edit/${row._id}`);
                            }}
                        >
                            <EditOutlined />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="View" placement="top">
                        <IconButton
                            color="primary"
                            onClick={() => {
                                setModalShow(true);
                                setViewId(row._id);
                            }}
                        >
                            <VisibilityIcon />
                        </IconButton>
                    </Tooltip>
                    {/* <Tooltip title="Delete" placement="top">
                        <IconButton
                            color="primary"
                            onClick={() => {
                                setShow(true);
                                setAction("delete");
                                setStatusId(row._id);
                                setWarning("Are you sure want to delete the template?");
                            }}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip> */}
                    <Tooltip title="Send Email" placement="right">
                        <IconButton
                            color="primary"
                            onClick={() => {
                                setMailShow(true);
                                setViewId(row._id);
                            }}
                        >
                            <SendIcon />
                        </IconButton>
                    </Tooltip>
                </>
            ),
        },
    ];

    const handleDelete = async (id) => {
        const res = await deleteTemplate(id);

        if (res.status == 200) {
            tostS(res?.data?.message);
            setShow(false);
            setRefresh(!refresh)
        } else {
            tostE(res?.data?.message);
            setShow(false);
        }
    }

    const toggleChecked = async (id, statusChange) => {
        const res = await changeStatus({
            id,
            status: statusChange == 1 ? 0 : 1,
        });

        setCurrentStatus("");

        if (res.status === 200 && res?.data?.message) {
            tostS(res?.data?.message);
            setRefresh(!refresh);
            setShow(false);
        } else {
            tostE(res?.data?.message);
            setShow(false);
        }
    };


    return (
        <Card>
            <CardHeader titleTypographyProps={{ variant: "h4" }} title="Email Template Management"></CardHeader>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                <TableFilter 
                    data={STATUS_OPTIONS}
                    value={status}
                    setValue={setStatus}      
                    filterName="Status"              
                />
                <Input
                    label="Search"
                    variant="standard"
                    value={searchItem}
                    placeholder="Search"
                    onChange={(e) => setSearchItem(e.target.value)}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="clear-search"
                                onClick={() => { setSearchItem("") }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </InputAdornment>
                    }

                />
                <Button size="large" type="button" variant="contained" sx={{ ml: 3, mr: 3 }} onClick={() => navigate("/email-template/add")}>
                    Add
                </Button>
            </Box>

            <CardContent>
                <DataTable
                    columns={columns}
                    data={filteredData ? filteredData : data}
                    pagination
                    paginationServer
                    paginationTotalRows={totalPages}
                    onChangePage={handlePageChange}
                    paginationPerPage={pageSize}
                    onSort={handleSort}
                    defaultSortField="createdAt"
                    defaultSortAsc={false}
                    paginationRowsPerPageOptions={[5, 10, 20, 50]}
                    onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
                        setPageSize(currentRowsPerPage);
                        setCurrentPage(currentPage);
                    }}
                />
            </CardContent>

            {modalShow ? <ViewEmailTemplate show={modalShow} onHide={() => setModalShow(false)} id={viewId} /> : ""}
            {mailShow ? <SendEmail show={mailShow} onHide={() => setMailShow(false)} id={viewId} onSend={() => { setMailShow(false); setRefresh(!refresh); }} /> : ""}
            <Dialog open={show} keepMounted onClose={handleClose} aria-describedby="alert-dialog-slide-description">
                <DialogTitle as="h2">{warning}</DialogTitle>

                <DialogActions>
                    <Button
                        variant="outlined"
                        style={{ backgroundColor: "white" }}
                        onClick={() => {
                            if (action == "delete") {
                                handleDelete(statusId);
                            } else if (action == "status") {
                                toggleChecked(statusId, currentStatus);
                            }
                        }}
                    >
                        Yes
                    </Button>
                    <Button variant="outlined" style={{ backgroundColor: "white" }} onClick={handleClose}>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </Card>
    );
}

export default EmailTemplateManagement;
