import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import categoryService from "src/api/categoryService";
import { capitalize } from "src/utils/helpers";

const ViewCategoryDetail = ({ id, show, onHide }) => {
  const [data, setData] = useState("");
  const userData = async () => {
    const response = await categoryService.getOneCategory(id);
    setData(response.data.data);
    console.log(response.data);
  };

  useEffect(() => {
    userData();
  }, []);
  
  return (
    <Dialog maxWidth="md" fullWidth={true} open={show} onClose={onHide}>
      <DialogTitle as="h2">Details</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2} sx={{ overflowWrap: "anywhere" }}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1">Name</Typography>
            <DialogContentText>
              {capitalize(data?.name)}{" "}
            </DialogContentText>
          </Grid>          
          <Grid item xs={12} md={12}>
            <Typography variant="subtitle1">Description</Typography>
            <DialogContentText>
              {capitalize(data?.description)}
            </DialogContentText>
          </Grid>
        </Grid>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => onHide()}
            type="submit"
            style={{ backgroundColor: "white" }}
          >
            Close
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default ViewCategoryDetail;
