import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import apiUsers from 'src/api/usersService';
import { FILE_URL } from 'src/utils/constants';
import { capitalize } from 'src/utils/helpers';


const ViewUserDetail = ({ id, show, onHide }) => {
    const [data, setData] = useState('')
    const userData = async () => {
        const response = await apiUsers.getOneUser(id);
        setData(response.data.data);
        console.log(response.data);
    };

    useEffect(() => {
        userData()
    }, [])
    
    return (

        <Dialog maxWidth="md" fullWidth={true} open={show} onClose={onHide}>
            <DialogTitle as='h2'>Details</DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle1">Profile Image</Typography>
                        <Avatar alt={data?.firstName} src={FILE_URL + data?.profileImage} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle1">Full Name</Typography>
                        <DialogContentText>{capitalize(data?.fullName)}</DialogContentText>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle1">Email</Typography>
                        <DialogContentText>{capitalize(data?.email)}</DialogContentText>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle1">Mobile Number</Typography>
                        <DialogContentText>{data?.phoneNumber}</DialogContentText>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle1">Gender</Typography>
                        <DialogContentText>{data?.gender}</DialogContentText>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle1">Date of Birth</Typography>
                        <DialogContentText>{moment(data?.dob).format("DD-MMM-yyyy")}</DialogContentText>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle1">Address</Typography>
                        <DialogContentText sx={{wordBreak:"break-all"}}>{data?.address ? data?.address : "-"}</DialogContentText>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle1">Bio</Typography>
                        <DialogContentText sx={{wordBreak:"break-all"}}>{data?.bio ? data?.bio : "-"}</DialogContentText>
                    </Grid>
                </Grid>
                <DialogActions>
                    <Button variant="outlined" onClick={() => onHide()} type="submit" style={{ backgroundColor: 'white' }} >
                        Close
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}

export default ViewUserDetail