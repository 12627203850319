import React, { useEffect, useState } from 'react'
import { Box, Button, Card, CardContent, CardHeader, Dialog, DialogActions, DialogTitle, FormControlLabel, FormGroup, IconButton, Input, InputAdornment, Switch, Tooltip } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from '@mui/icons-material/Check';
import { useNavigate } from 'react-router-dom';
import DataTable from "react-data-table-component";
import { capitalize } from 'src/utils/helpers';
import { EditOutlined } from '@mui/icons-material';
import { approveMerchant, changeStatus, deleteMerchant, getAllMerchant } from 'src/api/merchantServices';
import ViewMerchant from './ViewMerchant';
import { tostE, tostS } from 'src/utils/Toast';
import RejectMerchant from './RejectMerchant';
import { STATUS_OPTIONS } from "src/utils/constants";
import TableFilter from "src/components/shared/TableFilter";

export default function MerchantManagement() {
    const navigate = useNavigate();
    const [searchItem, setSearchItem] = useState("");
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [data, setData] = useState();
    const [warning, setWarning] = useState("");
    const [action, setAction] = useState("");
    const [show, setShow] = useState(false);
    const [currentStatus, setCurrentStatus] = useState("");
    const [statusId, setStatusId] = useState("");
    const [modalShow, setModalShow] = useState(false);
    const [rejectShow, setRejectShow] = useState(false);
    const [viewId, setViewId] = useState("");
    const [refresh, setRefresh] = useState(false);
    const [status, setStatus] = useState([]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleClose = () => setShow(false);

    const columns = [
        {
            name: "Business Name",
            sortable: true,
            selector: (row) => capitalize(row.businessName),
        },
        {
            name: "Email",
            sortable: true,
            selector: (row) => capitalize(row.email),
        },
        {
            name: "Phone Number",
            sortable: true,
            selector: (row) => capitalize(row.phoneNumber),
        },
        {
            name: "Categories",
            selector: (row) => (
                <span title={row?.categoryIds.map(item => item?.name).join(", ")}>
                    {row?.categoryIds.map(item => item?.name).join(", ")}
                </span>
            ),
        },
        {
            name: "Status",
            cell: (row) => (
                <>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={row.status}
                                    onClick={() => {
                                        setShow(true);
                                        setCurrentStatus(row.status);
                                        setStatusId(row._id);
                                        setAction("status");
                                        setWarning("Are you sure want to change the status? ");
                                    }}
                                    inputProps={{ "aria-label": "controlled" }}
                                />
                            }
                        />
                    </FormGroup>
                </>
            ),
        },
        {
            name: (
                <div style={{ textAlign: "center", marginLeft: "40px" }}>Action</div>
            ),
            cell: (row) => (
                <>
                    <Tooltip title="Edit" placement="left">
                        <IconButton
                            color="primary"
                            onClick={() => {
                                navigate(`/merchant/update/${row._id}`);
                            }}
                        >
                            <EditOutlined />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="View" placement="top" leaveDelay={50}>
                        <IconButton
                            color="primary"
                            onClick={() => {
                                setModalShow(true);
                                setViewId(row._id);
                            }}
                        >
                            <VisibilityIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete" placement="right">
                        <IconButton
                            color="primary"
                            onClick={() => {
                                setShow(true);
                                setStatusId(row._id);
                                setAction("delete");
                                setWarning("Are you sure want to delete this merchant?");
                            }}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                    {row.requestStatus==0 ? 
                    <>
                        <Tooltip title="Approve" placement="bottom">
                            <IconButton
                                color="primary"
                                onClick={() => {
                                    setShow(true);
                                    setStatusId(row._id);
                                    setAction("approve");
                                    setWarning("Are you sure want to approve this merchant?");
                                }}
                            >
                                <CheckIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Reject" placement="bottom">
                            <IconButton
                                color="primary"
                                onClick={() => {
                                    setRejectShow(true);
                                    setViewId(row._id);
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                    :null}
                </>
            ),
        },
    ];

    useEffect(() => {
        const body = {
            searchItem: searchItem ? searchItem : "",
            pageNumber: currentPage,
            pageSize: pageSize,
            status: status,
        };
        getAllMerchant(body).then((res) => {
            if (res.status == 200) {
                setData(res.data.data.result);
                setTotalPages(res.data.data.totalRecords);
            }
        }).catch((err) => {
            console.log(err)
        })
    }, [searchItem, currentPage, pageSize, refresh, status])

    const toggleChecked = async (id, statusChange) => {        
        const res = await changeStatus({
            id,
            status: statusChange == 1 ? 0 : 1,
        });

        setCurrentStatus("");

        if (res.status===200 && res?.data?.message) {            
            tostS(res?.data?.message);
            setRefresh(!refresh);
            setShow(false);
        } else {
            tostE(res?.data?.message);
            setShow(false);
        }
    };

    const handleApprove = async (id) => {        
        const res = await approveMerchant(id);

        if (res.status===200 && res?.data?.message) {            
            tostS(res?.data?.message);
            setRefresh(!refresh);
            setShow(false);
        } else {
            tostE(res?.data?.message);
            setShow(false);
        }
    };

    const deleteMerchantItem = async (id) => {
        setShow(false);
        const res = await deleteMerchant(id);
        if (res.status==200) {
            tostS(res?.data?.message);
            setRefresh(!refresh);
        } else {
            tostE(res?.data?.message);
        }
    };

    return (
        <Card>
            <CardHeader titleTypographyProps={{ variant: "h4" }} title="Merchant Management" />
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                }}
            >
                <TableFilter 
                    data={STATUS_OPTIONS}
                    value={status}
                    setValue={setStatus}      
                    filterName="Status"              
                />
                <Input
                    label="Search"
                    variant="standard"
                    value={searchItem}
                    placeholder="Search"
                    onChange={(e) => setSearchItem(e.target.value)}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="clear-search"
                                onClick={() => { setSearchItem("") }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </InputAdornment>
                    }

                />
                <Button
                    size="large"
                    type="submit"
                    variant="contained"
                    sx={{ ml: 3, mr: 3 }}
                    onClick={() => navigate("/merchant/add")}
                >
                    Add
                </Button>
            </Box>
            <CardContent>
                <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    paginationServer
                    paginationTotalRows={totalPages}
                    onChangePage={handlePageChange}
                    paginationPerPage={pageSize}
                    // paginationTotalRows={data ? data.length : 0}
                    paginationRowsPerPageOptions={[5, 10, 20, 50]}
                    onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
                        setPageSize(currentRowsPerPage);
                        setCurrentPage(currentPage);
                    }}
                />
            </CardContent>
            {modalShow ?
                <ViewMerchant show={modalShow} onHide={() => setModalShow(false)} id={viewId} />
            : null}
            {rejectShow ? 
                <RejectMerchant show={rejectShow} onHide={() => setRejectShow(false)} id={viewId} onReject = {()=>{setRejectShow(false);setRefresh(!refresh);}}/>
            :null}
            <Dialog
                open={show}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle as="h2">{warning}</DialogTitle>

                <DialogActions>
                    <Button
                        variant="outlined"
                        style={{ backgroundColor: "white" }}
                        onClick={() => {
                            if (action == "status") {
                                toggleChecked(statusId, currentStatus);
                            } else if (action == "delete") {
                                deleteMerchantItem(statusId)
                            }else if (action == "approve") {
                                handleApprove(statusId)
                            }
                        }}
                    >
                        Yes
                    </Button>
                    <Button
                        variant="outlined"
                        style={{ backgroundColor: "white" }}
                        onClick={handleClose}
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </Card>
    )
}
