import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    Stack,
    Tab,
    Tabs,
    Grid,
    Typography,
    FormLabel,
    TextField,
  } from "@mui/material";
  import React, { useEffect, useRef, useState } from "react";
  import EdiorJodit from "../../components/editor/editor";
  import { Icon } from "@iconify/react";
  import { Form, FormikProvider, useFormik } from "formik";
  import errorHandler from "../../utils/errorHandler";
  import cmsService from "src/api/cmsService";
  import { useNavigate } from "react-router-dom";
  import { tostE, tostS } from "src/utils/Toast";

  const RegistrationAgreement = () => {
    const navigate = useNavigate()
    const [cmsData, setCmsData] = useState("");
    const [id, setId] = useState("");
     function removeHtmlTags(text) {
       const clean = text?.replace(/<[^>]*>/g, "");
       return clean;
     }
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: {
        description: cmsData || "",
      },
      onSubmit: async (values) => {
        try {
           const textContent = removeHtmlTags(cmsData);
           if (textContent?.length < 10) {
             tostE("Minimum 10 characters required");
             return;
           }
  
          const cmsDataa = {
            description: cmsData,
            slug: "register-agreement",
            id: id,
          };
          let res = await cmsService.updateCms(cmsDataa);
          tostS(res?.data?.message);
          //  navigate("/dashboard");
        } catch (error) {
          errorHandler(error);
        }
      },
    });
    
    const fetchTerms = async () => {
      try {
        let params = {
          slug: "register-agreement",
        };
        const resp = await cmsService.getOneCms(params);
        setCmsData(resp?.data?.data?.description);
        setId(resp?.data?.data?._id);
      } catch (error) {
        errorHandler(error);
      }
    };
  
    useEffect(() => {
      fetchTerms();
    }, []);
  
    return (
      <>      
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              Registration Agreement
            </Typography>
          </Stack>
  
          <Container>
            <Card>
              <CardContent>
                <FormikProvider value={formik}>
                  <Form
                    autoComplete="off"
                    noValidate
                    onSubmit={formik.handleSubmit}
                  >
                    <Grid item xs={12} md={6}>
                      <FormLabel sx={{ display: "block" }}>Description</FormLabel>
  
                      <EdiorJodit
                        onChange={(html) => {
                          setCmsData(html);
                        }}
                        data={cmsData}
                        placeholder="Privacy Policy"
                      />
                    </Grid>
                    <Stack
                      spacing={2}
                      mt={5}
                      direction="row"
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        startIcon={<Icon icon="fa-solid:save" />}
                      >
                        Save
                      </Button>
                    </Stack>
                  </Form>
                </FormikProvider>
              </CardContent>
            </Card>
          </Container>
        </Container>
      </>
    );
  };
  
  export default RegistrationAgreement;
  