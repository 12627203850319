import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import categoryService from "src/api/categoryService";
import { getMerchantDetail } from "src/api/merchantServices";
import { capitalize } from "src/utils/helpers";

const ViewMerchant = ({ id, show, onHide }) => {
    const [data, setData] = useState("");
    const userData = async () => {
        const response = await getMerchantDetail(id);
        if (response.status === 200) {
            setData(response.data.data);
            console.log(response.data);
        }
    };

    useEffect(() => {
        userData();
    }, []);
    
    return (
        <Dialog maxWidth="md" fullWidth={true} open={show} onClose={onHide}>
            <DialogTitle as="h2">Details</DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={2} sx={{ overflowWrap: "anywhere" }}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle1">Business Name</Typography>
                        <DialogContentText>
                            {capitalize(data?.businessName)}{" "}
                        </DialogContentText>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle1">Represent Name</Typography>
                        <DialogContentText>
                            {capitalize(data?.representName)}{" "}
                        </DialogContentText>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle1">Email</Typography>
                        <DialogContentText>
                            {capitalize(data?.email)}{" "}
                        </DialogContentText>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle1">Phone Number</Typography>
                        <DialogContentText>
                            {capitalize(data?.phoneNumber)}{" "}
                        </DialogContentText>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle1">Categories</Typography>
                        <DialogContentText>
                            {data?.categoryIds && data?.categoryIds.map(item => item.name).join(", ")}
                        </DialogContentText>
                    </Grid>
                </Grid>
                <DialogActions>
                    <Button
                        variant="outlined"
                        onClick={() => onHide()}
                        type="submit"
                        style={{ backgroundColor: "white" }}
                    >
                        Close
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};

export default ViewMerchant;
