import React, { useEffect } from 'react'
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from "formik";
import { EMAIL_REGEX, NOSPACE_REGEX } from "src/utils/constants";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    TextField
} from "@mui/material";
import { getOneTemplate, sendMail } from 'src/api/emailTemplateServices';
import { tostE, tostS } from "src/utils/Toast";
import HtmlInputEditor from 'src/components/editor/HtmlInputEditor';

export default function SendEmail({ id, show, onHide, onSend }) {
    
    useEffect(()=>{
        getOneTemplate(id).then((response)=>{
            if (response.status === 200) {
                const respData = response.data.data;
                setValues({
                    ...values, 
                    subject:respData?.subject, 
                    content:respData?.content
                })
            }
        }).catch((err)=>{
            console.log(err)
        })
    },[])

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required("Email is required")
            .email("Please enter a valid email")
            .matches(EMAIL_REGEX, "Please enter a valid email address"),
        subject: Yup.string()
            .required("Represent name is required")
            .matches(NOSPACE_REGEX, "Please Enter a valid subject")
            .min(2, "Please enter at least 2 characters")
            .max(50, "Subject must not be more than 50 characters."),
        content: Yup.string().required("Content is required")   
    });
    const formik = useFormik({
        initialValues: {
            email: "",
            subject: "",
            content: "",
        },
        validationSchema,
        onSubmit: (values, {resetForm}) => {
            const payload = {
                id,
                email: values.email,
                subject:values.subject,
                content:values.content
            }
            console.log(payload)
            sendMail(payload).then((res) => {
                if (res.status == 200) {
                    tostS(res?.data?.message);
                    resetForm()
                    onSend();
                }
            }).catch((err) => {
                console.log(err);
                if (err?.response?.data) {
                    tostE(err.response.data.message);
                }
            })
        }
    })

    const { values, errors, touched, isSubmitting, handleSubmit, getFieldProps, setValues, setFieldValue } = formik;

    return (
        <Dialog maxWidth="md" fullWidth={true} open={show} onClose={onHide}>
            <DialogTitle as="h2">Send Email</DialogTitle>
            <DialogContent dividers>
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <Stack spacing={3}>
                            <TextField
                                fullWidth
                                name="reason"
                                label="Email"
                                {...getFieldProps("email")}
                                error={Boolean(touched.email && errors.email)}
                                helperText={touched.email && errors.email}
                            />
                        </Stack>
                        <Stack spacing={3} mt={2}>
                            <TextField
                                name="subject"
                                fullWidth
                                label="Subject"
                                {...getFieldProps("subject")}
                                error={Boolean(touched.subject && errors.subject)}
                                helperText={touched.subject && errors.subject}
                            />
                        </Stack>
                        <Stack spacing={3} mt={2}>
                            <HtmlInputEditor
                                editorState={values.content}
                                setEditorState={(data)=>{setFieldValue("content", data)}}
                                isInvalid={Boolean(touched.content && errors.content)}
                            />
                            {errors.content && touched.content ? <span className="form-error">{errors.content}</span> : null}
                        </Stack>
                        <DialogActions>
                            <Button
                                variant="contained"
                                type="submit"
                                loading={isSubmitting}
                                sx={{ my: 2 }}
                            >
                                Send
                            </Button>
                            <Button
                                variant="outlined"
                                onClick={() => onHide()}
                                type="button"
                                style={{ backgroundColor: "white" }}
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </Form>
                </FormikProvider>
            </DialogContent>
        </Dialog>
    )
}
