import React, { useState } from "react";
import { CKEditor } from 'ckeditor4-react';
import HtmlParser from "react-html-parser";

function HtmlInputEditor({ editorState, setEditorState, isInvalid = false, updateOptions }) {
 

    return (
        <div className={"editor-static " + (isInvalid ? " is-invalid" : "")}>
            <CKEditor
                initData={HtmlParser(editorState)}
                onChange={({ editor }) => {
                    if (updateOptions) {
                        updateOptions(editor.getData())
                        return
                    }
                    setEditorState(editor.getData());
                }}
                onPaste={({ editor }) => {
                    setEditorState(editor.getData())
                }}
                onBlur={({ editor }) => {
                    setEditorState(editor.getData());
                }}
                readOnly={false}
                onInstanceReady={({ editor }) => {
                    editor.setData(HtmlParser(editorState));
                }}
                config={{
                    allowedContent: true,
                    entities: false,
                    basicEntities: false,
                    entities_greek: false,
                    entities_latin: false,
                    entities_additional: '',
                    preset: 'full',
                    extraPlugins: `codesnippet,forms,iframe,font,colordialog,colorbutton,embed,emoji,justify,bidi,exportpdf,templates,smiley,showblocks,selectall,scayt,find,entities,basicstyles,popup,uploadimage`,
                    font_names: 'default;Arial;Comic Sans MS;Courier New;Georgia;Lucida Sans Unicode;Tahoma;Times New Roman;Trebuchet MS;Verdana;Segeo UI Light;',
                }}

            />
        </div>

    );
}

export default HtmlInputEditor;
