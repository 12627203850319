import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import {
  MOBILE_REGEX,
  NOSPACE_REGEX,
  NOSPACE_REGEX_EMAIL,
  PASSWORDS_REGEX,
} from "../../../utils/constants";
import errorHandler from "../../../utils/errorHandler";
import cmsService from "src/api/cmsService";
import { values } from "lodash";
import EdiorJodit from "src/components/editor/editor";
import { tostS } from "src/utils/Toast";

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .required("Question is required")
    .matches(NOSPACE_REGEX, "Please Enter a valid Question")
    .min(2, "Please enter at least 2 characters")
    .max(100, "Question must not be more than 100 characters."),
});

export default function EditFaq() {
  const [data, setData] = useState("");
  console.log(data)
  const navigate = useNavigate();
  const { state } = useLocation();
  const [cmsData, setCmsData] = useState("");

  const faqData = async () => {
    const {data} = await cmsService.getOneFaq(state.id);
    setCmsData(data.data.description)
    setData(data.data);
  };
  useEffect(() => {
    faqData();
  }, []);
  const formik = useFormik({
    enableReinitialize:true,
    initialValues: { title: data?.title, description: data?.description },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      cmsService
        .updateCms({
          id: state.id,
          slug: "faqs",
          title: values.title,
          description: cmsData,
        })
        .then((res) => {
          tostS(res?.data?.message);
          navigate("/faq");
        })
        .catch((err) => {
          errorHandler(err);
          setSubmitting(false);
        });
    },
  });

  const { values,errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <>
      <Card>
        <CardContent>
          <FormikProvider value={formik} style={{ padding: "34px 30px" }}>
            <Typography variant="h4" sx={{ mb: 2 }} align="start">
              Edit FAQ
            </Typography>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={3}>
                <TextField
                  name="title"
                  label="Question"
                  {...getFieldProps("title")}
                  focused
                  error={Boolean(touched.title && errors.title)}
                  helperText={touched.title && errors.title}
                />
                <EdiorJodit
                  onChange={(html) => {
                    setCmsData(html);
                  }}
                  data={cmsData}
                  placeholder="Answer"
                />
              </Stack>

              <Button
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ my: 2 }}
              >
                Update
              </Button>
              <Button
                size="large"
                type="button"
                variant="contained"
                onClick={() => {
                  navigate("/faq");
                }}
                sx={{ my: 2, ml: 4 }}
              >
                Cancel
              </Button>
            </Form>
          </FormikProvider>
        </CardContent>
      </Card>
    </>
  );
}
