import { Construction, EditOutlined, Search } from "@mui/icons-material";
import { Box, Button, Card, CardContent, CardHeader, FormControlLabel, FormGroup, IconButton, Input, InputAdornment, Switch, TextField, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import categoryService from "src/api/categoryService";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ViewUserDetail from "./ViewCategory";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from '@mui/icons-material/Close';
import { tostE, tostS } from "src/utils/Toast";
import { capitalize } from "src/utils/helpers";

function CategoryManagement() {
  const [data, setData] = useState();
  const navigate = useNavigate();
  const [check, setCheck] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [modalShow, setModalShow] = useState(false);
  const [currentStatus, setCurrentStatus] = useState("");
  const [statusId, setStatusId] = useState("");
  const [viewId, setViewId] = useState("");
  const [editId, setEditId] = useState("");
  const [searchItem, setSearchItem] = useState("");
  const [warning, setWarning] = useState("");
  const [action, setAction] = useState("");
  const [show, setShow] = useState(false);
  
  const categoryData = async () => {
    const body = {
      searchItem: searchItem ? searchItem : "",
      pageNumber: currentPage,
      pageSize: pageSize,
      status: "",
    };
    await categoryService
      .getAllCategories(body)
      .then((res) => {
        if(res.status==200){
          setData(res.data.data.result);
          setTotalPages(res.data.data.totalRecords);
        }
      })
      .catch((err) => {
        setData("");
        setTotalPages("");
      });
  };

  const handlePageChange = (page) => {    
    setCurrentPage(page);
  };

  const toggleChecked = async (id, statusChange) => {
    setShow(false);
    const res = await categoryService.changeStatus({
      id,
      status: statusChange == 1 ? 0 : 1,
    });

    setCurrentStatus("");
    setCheck(!check);

    if (res?.data?.message) {
      const updatedData = data.map((row) =>
        row._id === id ? { ...row, status: !row.status } : row
      );
      setData(updatedData);
      tostS(res?.data?.message);
    } else {
      // Revert local state in case of API error
      setData(data);
      tostE(res?.data?.message);
    }
  };
    const deleteCategory = async (id) => {
      setShow(false);
      const res = await categoryService.deleteCategory(id);
      if (res?.data?.message) {
        tostS(res?.data?.message);
        categoryData();
      } else {
        tostE(res?.data?.message);
      }
    };

  useEffect(() => {
    categoryData();
  }, [searchItem, currentPage, pageSize]);

  const handleClose = () => setShow(false);
  const columns = [
    {
      name: "Name",
      sortable:true,
      selector: (row) => capitalize(row.name),
    },
    {
      name: "Status",
      cell: (row) => (
        <>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={row.status}
                  onClick={() => {
                    setShow(true);
                    setCurrentStatus(row.status);
                    setStatusId(row._id);
                    setAction("status");
                    setWarning("Are you sure want to change the status? ");
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
            />
          </FormGroup>
        </>
      ),
    },
    {
      name: (
        <div style={{ textAlign: "center", marginLeft: "40px" }}>Action</div>
      ),
      cell: (row) => (
        <>
          <Tooltip title="Edit" placement="left">
            <IconButton
              color="primary"
              onClick={() => {
                setEditId(row._id);
                navigate("/category/edit", {
                  state: { id: row._id },
                });
              }}
            >
              <EditOutlined />
            </IconButton>
          </Tooltip>

          <Tooltip title="View" placement="top" leaveDelay={50}>
            <IconButton
              color="primary"
              onClick={() => {
                setModalShow(true);
                setViewId(row._id);
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete" placement="right">
            <IconButton
              color="primary"
              onClick={() => {
                setShow(true);
                setStatusId(row._id);
                setAction("delete");
                setWarning("Are you sure want to delete this category?");
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <Card>
      <CardHeader
        titleTypographyProps={{ variant: "h4" }}
        title="Category Management"
      ></CardHeader>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Input
          label="Search"
          variant="standard"
          value={searchItem}
          placeholder="Search"
          onChange={(e) => setSearchItem(e.target.value)}          
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="clear-search"
                onClick={()=>{setSearchItem("")}}                
              >
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          }
          
        />
        <Button
          size="large"
          type="submit"
          variant="contained"
          sx={{ ml: 3, mr: 3 }}
          onClick={() => navigate("/category/add")}
        >
          Add
        </Button>
      </Box>

      <CardContent>
        <DataTable
          columns={columns}
          data={data}
          pagination
          paginationServer
          paginationTotalRows={totalPages}
          onChangePage={handlePageChange}
          paginationPerPage={pageSize}
          // paginationTotalRows={data ? data.length : 0}
          paginationRowsPerPageOptions={[5, 10, 20, 50]}
          onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
            setPageSize(currentRowsPerPage);
            setCurrentPage(currentPage);
          }}
        />
      </CardContent>

      {modalShow ? (
        <ViewUserDetail
          show={modalShow}
          onHide={() => setModalShow(false)}
          id={viewId}
        />
      ) : (
        ""
      )}
      <Dialog
        open={show}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle as="h2">{warning}</DialogTitle>

        <DialogActions>
          <Button
            variant="outlined"
            style={{ backgroundColor: "white" }}
            onClick={() => {
              if (action == "status") {
                toggleChecked(statusId, currentStatus);
              } else if (action == "delete"){
                deleteCategory(statusId)
              }
            }}
          >
            Yes
          </Button>
          <Button
            variant="outlined"
            style={{ backgroundColor: "white" }}
            onClick={handleClose}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}

export default CategoryManagement;
