import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, CardContent, Paper, Stack, TextField, Typography } from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { toast } from "react-toastify";
import { MOBILE_REGEX, NOSPACE_REGEX, NOSPACE_REGEX_EMAIL, PASSWORDS_REGEX } from '../../utils/constants';
import errorHandler from '../../utils/errorHandler';
import categoryService from "src/api/categoryService";
import { tostS } from 'src/utils/Toast';


const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .matches(NOSPACE_REGEX, "Please Enter a valid name")
    .min(2, "Please enter at least 2 characters")
    .max(50, "Name must not be more than 50 characters."),
  // title: Yup.string()
  //   .required("Title is required")
  //   .matches(NOSPACE_REGEX, "Please Enter a valid title")
  //   .min(2, "Please enter at least 2 characters")
  //   .max(50, "Title must not be more than 50 characters."),
  description: Yup.string().required("Description is required")
  .matches(NOSPACE_REGEX, "Please Enter a valid Description")
   .min(2, "Please enter at least 2 characters")
    .max(250, "Description must not be more than 250 characters."),
});


export default function AddCategory() {
    const navigate = useNavigate();
    const { state } = useLocation();

   
    const formik = useFormik({
      initialValues: { name : "",title: "", description: "" },
      validationSchema,
      onSubmit: (values, { setSubmitting }) => {
        categoryService
          .addCategory({
            name: values.name,
            // title: values.title,
            description: values.description,
          })
          .then((res) => {
            tostS(res?.data?.message);
            navigate("/category");
          })
          .catch((err) => {
            errorHandler(err);
            setSubmitting(false);
          });
      },
    });

    const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

    return (
      <>
        <Card>
          <CardContent>
            <FormikProvider value={formik} style={{ padding: "34px 30px" }}>
              <Typography variant="h4" sx={{ mb: 2 }} align="start">
                Add Category
              </Typography>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                  <TextField
                    name="name"
                    label="Name"
                    {...getFieldProps("name")}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                  {/* <TextField
                    name="title"
                    label="Title"
                    {...getFieldProps("title")}
                    error={Boolean(touched.title && errors.title)}
                    helperText={touched.title && errors.title}
                  /> */}
                  <TextField
                    name="description"
                    label="Description"
                    {...getFieldProps("description")}
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                  />
                </Stack>

                <Button
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  sx={{ my: 2 }}
                >
                  Save
                </Button>
                <Button
                  size="large"
                  variant="contained"
                  onClick={() => {
                    navigate("/category");
                  }}
                  sx={{ my: 2, ml: 4 }}
                >
                  Cancel
                </Button>
              </Form>
            </FormikProvider>
          </CardContent>
        </Card>
      </>
    );
}
