import AxiosInterceptor from "../utils/AxiosInterceptor";

export const getCategories = async () => {
    return await AxiosInterceptor().get(`merchant/getCategories`);
};

export const getAllMerchant = async (payload) => {
    return await AxiosInterceptor().post(`merchant/getAllMerchant`,payload);
};

export const addMerchant = async (payload) => {
    return await AxiosInterceptor().post(`merchant/addMerchant`,payload);
};

export const updateMerchant = async (payload) => {
    return await AxiosInterceptor().post(`merchant/updateMerchant`,payload);
};

export const changeStatus = async (payload) => {
    return await AxiosInterceptor().post(`merchant/changeStatus`,payload);
};

export const getMerchantDetail = async (id) => {
    return await AxiosInterceptor().get(`merchant/getOneMerchant/${id}`);
};

export const deleteMerchant = async (id) => {
    return await AxiosInterceptor().get(`merchant/deleteMerchant/${id}`);
};

export const approveMerchant = async (id) => {
    return await AxiosInterceptor().get(`merchant/approveMerchant/${id}`);
};

export const rejectMerchant = async (payload) => {
    return await AxiosInterceptor().post(`merchant/rejectMerchant`,payload);
};
