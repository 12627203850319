import React from 'react'
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Card, CardContent, Grid, TextField, Typography } from '@mui/material';
import { NOSPACE_REGEX } from 'src/utils/constants';
import HtmlInputEditor from 'src/components/editor/HtmlInputEditor';
import { useNavigate } from 'react-router-dom';
import { addTemplate } from 'src/api/emailTemplateServices';
import { tostE, tostS } from 'src/utils/Toast';


export default function AddEmailTemplate() {
    const navigate = useNavigate();
    
    const validationSchema = Yup.object().shape({
        title: Yup.string()
            .required("Title is required")
            .matches(NOSPACE_REGEX, "Please Enter a valid title")
            .min(2, "Please enter at least 2 characters")
            .max(50, "Title must not be more than 50 characters."),
        subject: Yup.string()
            .required("Represent name is required")
            .matches(NOSPACE_REGEX, "Please Enter a valid subject")
            .min(2, "Please enter at least 2 characters")
            .max(50, "Subject must not be more than 50 characters."),
        content: Yup.string().required("Content is required")            
    });

    const formik = useFormik({
        enableReinitialize:true,
        initialValues: {
            title: "",
            subject: "",
            content: "",
        },
        validationSchema,
        onSubmit: (values) => {            
            addTemplate(values).then((res) => {
                if (res.status == 200) {
                    tostS(res.data.message)
                    navigate("/email-template")
                }
            }).catch((err) => {
                console.log(err);
                if (err.response.data) {
                    tostE(err.response.data.message);
                }
            })
        },
    });

    const { values, errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

    return (
        <Card>
            <CardContent>
                <Typography variant="h4" sx={{ mb: 2 }}>
                    Add Email Template
                </Typography>
                <FormikProvider value={formik} style={{ padding: "34px 30px" }}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item lg={6} md={12}>
                                <TextField
                                    name="title"
                                    fullWidth
                                    label="Title"
                                    {...getFieldProps("title")}
                                    error={Boolean(touched.title && errors.title)}
                                    helperText={touched.title && errors.title}
                                />
                            </Grid>
                            <Grid item lg={6} md={12}>
                                <TextField
                                    name="subject"
                                    fullWidth
                                    label="Subject"
                                    {...getFieldProps("subject")}
                                    error={Boolean(touched.subject && errors.subject)}
                                    helperText={touched.subject && errors.subject}
                                />
                            </Grid>
                            <Grid item lg={12} md={12}>
                                <HtmlInputEditor
                                    editorState={values.content}
                                    setEditorState={(data)=>{setFieldValue("content", data)}}
                                    isInvalid={Boolean(touched.content && errors.content)}
                                />
                                {errors.content && touched.content ? <span className="form-error">{errors.content}</span> : null}
                            </Grid>
                        </Grid>
                        <Button
                            size="large"
                            type="submit"
                            variant="contained"
                            loading={isSubmitting.toString()}
                            sx={{ my: 2 }}
                        >
                            Save
                        </Button>
                        <Button
                            size="large"
                            variant="contained"
                            onClick={() => {
                                navigate("/email-template");
                            }}
                            sx={{ my: 2, ml: 4 }}
                        >
                            Cancel
                        </Button>
                    </Form>
                </FormikProvider>
            </CardContent>
        </Card>
    )
}
