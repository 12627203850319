// component
import SvgColor from '../../../components/svg-color';
import QuizIcon from "@mui/icons-material/Quiz";
import PolicyIcon from "@mui/icons-material/Policy";
import CategoryIcon from "@mui/icons-material/Category";
import StorefrontIcon from '@mui/icons-material/Storefront';
import EmailIcon from '@mui/icons-material/Email';
// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: icon("ic_analytics"),
  }, 
  {
    title: "User Management",
    path: "/user",
    icon: icon("user_management"),
  },  
  {
    title: "Merchant Management",
    path: "/merchant",
    icon: <StorefrontIcon />,
  },
  {
    title: "Category Management",
    path: "/category",
    icon: <CategoryIcon />,
  },
  {
    title: "Email Template management",
    path: "/email-template",
    icon: <EmailIcon />,
  },
  {
    title: "CMS Management",

    icon: icon("cms"),
    children: [
      {
        title: "FAQ Management",
        path: "/faq",
        icon: <QuizIcon />,
      },
      {
        title: "Privacy Policy",
        path: "/cms/privacy-policy",
        icon: <PolicyIcon />,
      },
      {
        title: "Terms & Conditions",
        path: "/cms/terms-conditions",
        icon: icon("cms"),
      },
      {
        title: "Registration Agreement",
        path: "/cms/reg-agreement",
        icon: icon("cms"),
      },
    ],
  }
];

export default navConfig;
