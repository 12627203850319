import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import {
  MOBILE_REGEX,
  NOSPACE_REGEX,
  NOSPACE_REGEX_EMAIL,
  PASSWORDS_REGEX,
} from "../../utils/constants";
import errorHandler from "../../utils/errorHandler";
import categoryService from "src/api/categoryService";
import { values } from "lodash";
import { tostS } from "src/utils/Toast";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .matches(NOSPACE_REGEX,"Please Enter a valid name")
    .min(2, "Please enter at least 2 characters")
    .max(50, "Name must not be more than 50 characters."),
  // title: Yup.string()
  //   .required("Title is required")
  //   .matches(NOSPACE_REGEX, "Please Enter a valid title")
  //   .min(2, "Please enter at least 2 characters")
  //   .max(50, "Title must not be more than 50 characters."),
  description: Yup.string()
    .required("Description is required")
    .matches(NOSPACE_REGEX, "Please Enter a valid Description")
    .min(2, "Please enter at least 2 characters")
    .max(250, "Description must not be more than 250 characters."),
});

export default function EditCategory() {
  const [data, setData] = useState("");
  console.log(data)
  const navigate = useNavigate();
  const { state } = useLocation();
  const CategoryData = async () => {
    const { data } = await categoryService.getOneCategory(state.id);
    console.log(data.data)
    setData(data.data);
  };
  useEffect(() => {
    CategoryData();
  }, []);
  const formik = useFormik({
    enableReinitialize:true,
    initialValues: {name : data.name, title: data?.title, description: data?.description },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      categoryService
        .updateCategory({
          id: state.id,
          name: values.name,
          // title: values.title,
          description: values.description,
        })
        .then((res) => {
          tostS(res?.data?.message);
          navigate("/category");
        })
        .catch((err) => {
          errorHandler(err);
          setSubmitting(false);
        });
    },
  });

  const { values,errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <>
      <Card>
        <CardContent>
          <FormikProvider value={formik} style={{ padding: "34px 30px" }}>
            <Typography variant="h4" sx={{ mb: 2 }} align="start">
              Edit Category
            </Typography>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={3}>
                <TextField
                  name="name"
                  label="Name"
                  focused
                  {...getFieldProps("name")}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
                {/* <TextField
                  name="title"
                  label="Title"
                  {...getFieldProps("title")}
                  focused
                  error={Boolean(touched.title && errors.title)}
                  helperText={touched.title && errors.title}
                /> */}
                <TextField
                  name="description"
                  label="Description"
                  focused
                  {...getFieldProps("description")}
                  error={Boolean(touched.description && errors.description)}
                  helperText={touched.description && errors.description}
                />
              </Stack>

              <Button
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ my: 2 }}
              >
                Update
              </Button>
              <Button
                size="large"
                type="button"
                variant="contained"
                onClick={() => {
                  navigate("/Category");
                }}
                sx={{ my: 2, ml: 4 }}
              >
                Cancel
              </Button>
            </Form>
          </FormikProvider>
        </CardContent>
      </Card>
    </>
  );
}
