import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import cmsService from "src/api/cmsService";
import { capitalize } from "src/utils/helpers";

const ViewFaqDetail = ({ id, show, onHide }) => {
  const [data, setData] = useState("");
  const userData = async () => {
    const response = await cmsService.getOneFaq(id);
    setData(response.data.data);    
  };

  useEffect(() => {
    userData();
  }, []);
  
  return (
    <Dialog maxWidth="md" fullWidth={true} open={show} onClose={onHide}>
      <DialogTitle as="h2">Details</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Typography variant="subtitle1">Question</Typography>
            {/* <br /> */}
            <DialogContentText sx={{marginBlockStart: "1em"}}> 
              {capitalize(data?.title)}{" "}
            </DialogContentText>
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography variant="subtitle1">Answer</Typography>
            <DialogContentText>
              <Typography
                dangerouslySetInnerHTML={{ __html: data.description }}
              />
            </DialogContentText>
          </Grid>
        </Grid>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => onHide()}
            type="submit"
            style={{ backgroundColor: "white" }}
          >
            Close
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default ViewFaqDetail;
